@import url(https://use.fontawesome.com/releases/v5.3.1/css/all.css);

.map {
  width: 710px;
  margin: 0 auto;
}

ul.map-list {
  margin-left: 0;
}

li.map-item {
  list-style: none;
  float: left;
  position: static;
  top: auto;
  left: auto;
  width: auto;
  height: 1em;
  text-align: left;
  display: list-item;
  font-size: 13px;
  line-height: 1.1;
  font-weight: 700;
  color: #fff;
  padding: 1px;
}

li.map-item a.area-link {
  -webkit-transition: 0.2s;
  transition: 0.2s;
  text-decoration: none;
  background: none;
  color: #b58d51 !important;
  font-weight: normal;
  font-size: 1em;
  width: auto;
  height: auto;
  display: inline;
}

.map-item .area-link {
  display: table;
  width: 100%;
  height: 100%;
  overflow: hidden;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #fff !important;
  padding: 3px 0;
  text-decoration: none;
  font-size: 75%;
  white-space: nowrap;
  -webkit-transition: 0.2s ease-out;
  transition: 0.2s ease-out;
  position: relative;
}

li.map-item a.area-link:hover {
  background: none;
  color: #b58d51 !important;
  font-weight: normal;
  opacity: 0.7;
}

.map-item label span {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.map-item input[type=checkbox] {
  display: none;
}

.area7, .area9, .area21, .area25, .area30, .area31, .area39, .area47 {
  float: none !important;
}

ul.region-list {
  display: none;
}

.map-list {
  display: block;
  position: relative;
  width: 100%;
  height: 451px;
  margin: 0 auto;
  font-size: 14px;
  line-height: 1.2;
  text-align: left;
  padding: 0;
}

.map-item {
  position: relative;
  display: block;
  width: 7%;
  height: 7%;
  text-align: center;
  font-size: 14px;
  line-height: 1.1;
  font-weight: 700;
  color: #fff;
  list-style: none;
  padding: 1px;
}

a.area-link {
  display: table;
  width: 100%;
  height: 100%;
  overflow: hidden;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  color: #fff;
  padding: 3px 0;
  text-decoration: none;
  border-radius: 0px;
  font-size: 75%;
  white-space: nowrap;
  -webkit-transition: 0.2s ease-out;
  transition: 0.2s ease-out;
}

a.area-link:hover {
  opacity: 0.7;
  color: #fff !important;
}

.map-item .map-item-space {
  display: table-cell;
  vertical-align: middle;
}

.map .region1 .area-link, .map .region1 .area-link:hover {
  background-color: #0086cf;
}

.map .region2 .area-link, .map .region2 .area-link:hover {
  background-color: #00afeb;
}

.map .region3 .area-link, .map .region3 .area-link:hover {
  background-color: #00a239;
}

.map .region4 .area-link, .map .region4 .area-link:hover {
  background-color: #91bc2f;
}

.map .region5 .area-link, .map .region5 .area-link:hover {
  background-color: #d0b20e;
}

.map .region6 .area-link, .map .region6 .area-link:hover {
  background-color: #ed730d;
}

.map .region7 .area-link, .map .region7 .area-link:hover {
  background-color: #eb81af;
}

.map .region8 .area-link, .map .region8 .area-link:hover {
  background-color: #b05da0;
}

.map .region9 .area-link, .map .region9 .area-link:hover {
  background-color: #7166a2;
}

.map-list .area1 {
  position: absolute;
  top: 0;
  right: 0;
  width: 136px;
  height: 100px;
}

.map-list .area2 {
  position: absolute;
  top: 133px;
  right: 37px;
  width: 98px;
  height: 27px;
}

.map-list .area3 {
  position: absolute;
  top: 163px;
  right: 88px;
  width: 47px;
  height: 28px;
}

.map-list .area4 {
  position: absolute;
  top: 163px;
  right: 37px;
  width: 47px;
  height: 28px;
}

.map-list .area5 {
  position: absolute;
  top: 194px;
  right: 88px;
  width: 47px;
  height: 28px;
}

.map-list .area6 {
  position: absolute;
  top: 194px;
  right: 37px;
  width: 49px;
  height: 28px;
}

.map-list .area7 {
  position: absolute;
  top: 224px;
  right: 37px;
  width: 49px;
  height: 28px;
}

.map-list .area8 {
  position: absolute;
  top: 255px;
  right: 122px;
  width: 53px;
  height: 27px;
}

.map-list .area9 {
  position: absolute;
  top: 255px;
  right: 72px;
  width: 49px;
  height: 27px;
}

.map-list .area10 {
  position: absolute;
  top: 284px;
  right: 72px;
  width: 49px;
  height: 27px;
}

.map-list .area11 {
  position: absolute;
  top: 255px;
  right: 39px;
  width: 32px;
  height: 56px;
}

.map-list .area12 {
  position: absolute;
  top: 313px;
  right: 72px;
  width: 49px;
  height: 27px;
}

.map-list .area13 {
  position: absolute;
  top: 313px;
  right: 122px;
  width: 34px;
  height: 58px;
}

.map-list .area14 {
  position: absolute;
  top: 313px;
  right: 39px;
  width: 32px;
  height: 56px;
}

.map-list .area15 {
  position: absolute;
  top: 224px;
  right: 88px;
  width: 47px;
  height: 28px;
}

.map-list .area16 {
  position: absolute;
  top: 255px;
  right: 177px;
  width: 31px;
  height: 56px;
}

.map-list .area17 {
  position: absolute;
  top: 284px;
  right: 122px;
  width: 53px;
  height: 27px;
}

.map-list .area18 {
  position: absolute;
  top: 313px;
  right: 158px;
  width: 32px;
  height: 58px;
}

.map-list .area19 {
  position: absolute;
  top: 224px;
  right: 137px;
  width: 64px;
  height: 28px;
}

.map-list .area20 {
  position: absolute;
  top: 206px;
  right: 203px;
  width: 38px;
  height: 46px;
}

.map-list .area21 {
  position: absolute;
  top: 256px;
  right: 243px;
  width: 48px;
  height: 27px;
}

.map-list .area22 {
  position: absolute;
  top: 255px;
  right: 210px;
  width: 31px;
  height: 56px;
}

.map-list .area23 {
  position: absolute;
  top: 313px;
  right: 192px;
  width: 32px;
  height: 58px;
}

.map-list .area24 {
  position: absolute;
  top: 284px;
  right: 243px;
  width: 48px;
  height: 27px;
}

.map-list .area25 {
  position: absolute;
  top: 313px;
  right: 226px;
  width: 31px;
  height: 67px;
}

.map-list .area26 {
  position: absolute;
  top: 256px;
  right: 293px;
  width: 31px;
  height: 55px;
}

.map-list .area27 {
  position: absolute;
  top: 313px;
  right: 259px;
  width: 32px;
  height: 35px;
}

.map-list .area28 {
  position: absolute;
  top: 256px;
  right: 325px;
  width: 31px;
  height: 55px;
}

.map-list .area29 {
  position: absolute;
  top: 313px;
  right: 292px;
  width: 32px;
  height: 35px;
}

.map-list .area30 {
  position: absolute;
  top: 350px;
  right: 259px;
  width: 65px;
  height: 29px;
}

.map-list .area31 {
  position: absolute;
  top: 256px;
  left: 304px;
  width: 49px;
  height: 27px;
}

.map-list .area32 {
  position: absolute;
  top: 256px;
  left: 254px;
  width: 49px;
  height: 27px;
}

.map-list .area33 {
  position: absolute;
  top: 284px;
  left: 304px;
  width: 49px;
  height: 27px;
}

.map-list .area34 {
  position: absolute;
  top: 284px;
  left: 254px;
  width: 49px;
  height: 27px;
}

.map-list .area35 {
  position: absolute;
  top: 256px;
  left: 222px;
  width: 31px;
  height: 55px;
}

.map-list .area36 {
  position: absolute;
  top: 336px;
  left: 293px;
  width: 63px;
  height: 32px;
}

.map-list .area37 {
  position: absolute;
  top: 336px;
  left: 229px;
  width: 63px;
  height: 32px;
}

.map-list .area38 {
  position: absolute;
  top: 370px;
  left: 229px;
  width: 63px;
  height: 32px;
}

.map-list .area39 {
  position: absolute;
  top: 370px;
  left: 293px;
  width: 63px;
  height: 32px;
}

.map-list .area40 {
  position: absolute;
  top: 260px;
  left: 110px;
  width: 80px;
  height: 29px;
}

.map-list .area41 {
  position: absolute;
  top: 291px;
  left: 152px;
  width: 39px;
  height: 38px;
}

.map-list .area42 {
  position: absolute;
  top: 291px;
  left: 110px;
  width: 41px;
  height: 77px;
}

.map-list .area43 {
  position: absolute;
  top: 260px;
  left: 76px;
  width: 32px;
  height: 56px;
}

.map-list .area44 {
  position: absolute;
  top: 260px;
  left: 44px;
  width: 31px;
  height: 75px;
}

.map-list .area45 {
  position: absolute;
  top: 331px;
  left: 152px;
  width: 39px;
  height: 38px;
}

.map-list .area46 {
  position: absolute;
  top: 372px;
  left: 111px;
  width: 80px;
  height: 29px;
}

.map-list .area47 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 33px;
  height: 68px;
}

.region-list {
  padding: 0;
  width: 440px;
}

.region-item {
  position: relative;
  display: block;
  height: 7%;
  text-align: center;
  font-size: 14px;
  line-height: 1.1;
  font-weight: 700;
  list-style: none;
  padding: 1px !important;
  float: left;
  margin-right: 10px;
  margin-bottom: 3px;
}

a.region-link {
  display: table;
  width: 100%;
  height: 100%;
  overflow: hidden;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 3px 0;
  text-decoration: none;
  font-size: 1em;
  white-space: nowrap;
  -webkit-transition: 0.2s ease-out;
  transition: 0.2s ease-out;
}

.region-item-space {
  display: table-cell;
  vertical-align: middle;
}

a.region-link:hover {
  opacity: 0.7;
}

.region-all {
  font-size: 13px;
  color: #b58d51;
  font-weight: normal;
  background: none;
  margin-left: 10px;
}

.map-item input[type=checkbox]:checked + label {
  -webkit-filter: saturate(200%);
  filter: saturate(200%);
}

.map-item input[type=checkbox]:checked + label span::before {
  content: "\f00c";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  padding: 0;
  margin: 0 auto;
  margin-right: auto;
  display: inline-block;
  text-align: center;
  color: #fff;
  margin-right: 2px;
}

.map-item.area11 input[type=checkbox]:checked + label span::before, .map-item.area13 input[type=checkbox]:checked + label span::before, .map-item.area14 input[type=checkbox]:checked + label span::before, .map-item.area16 input[type=checkbox]:checked + label span::before, .map-item.area18 input[type=checkbox]:checked + label span::before, .map-item.area20 input[type=checkbox]:checked + label span::before, .map-item.area22 input[type=checkbox]:checked + label span::before, .map-item.area23 input[type=checkbox]:checked + label span::before, .map-item.area25 input[type=checkbox]:checked + label span::before, .map-item.area26 input[type=checkbox]:checked + label span::before, .map-item.area27 input[type=checkbox]:checked + label span::before, .map-item.area28 input[type=checkbox]:checked + label span::before, .map-item.area29 input[type=checkbox]:checked + label span::before, .map-item.area35 input[type=checkbox]:checked + label span::before, .map-item.area42 input[type=checkbox]:checked + label span::before, .map-item.area43 input[type=checkbox]:checked + label span::before, .map-item.area44 input[type=checkbox]:checked + label span::before, .map-item.area47 input[type=checkbox]:checked + label span::before {
  display: block;
}
